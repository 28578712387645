import { Button, TextField, Typography, Grid, Box, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { MbBackdrop } from "../mb_components/mb_backdrop";
import { MbMessage } from "../mb_components/mb_message";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RemoveHash, SaveAna } from "../mb_constants";
import { MbApi_CustomerSupplierCreate } from "../mb_api/mb_api_customer_supplier";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MbValidatorVat } from "../mb_validator/mb_validator_vat";
import { MbValidatorAgent } from "../mb_validator/mb_validator_agent";
import { MbValidatorCausal } from "../mb_validator/mb_validator_causal";
import { MbValidatorListPrice } from "../mb_validator/mb_validator_list_price";
import { MbValidatorVector } from "../mb_validator/mb_validator_vector";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MbValidatorCustomerType } from "../mb_validator/mb_validator_customer_type";
import { MbValidatorNations } from "../mb_validator/mb_validator_nations";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MbValidatorProvince } from "../mb_validator/mb_validator_province";
import { MbValidatorComuni } from "../mb_validator/mb_validator_comuni";
import { MbValidatorPayment } from "../mb_validator/mb_validator_payment";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";


const MbAnagraficaNewCustomerSupplier = ({structure, comeback, ent_type}) => {

    const [id, setId] = useState(null);
    const [customer_supplierCode, setCustomerSupplierCode] = useState(null);
    const [customer_supplierDesc, setCustomerSupplierDesc] = useState(null);
    const [entity_type, setEntity_type] = useState(null);
    const [sdi, setSdi] = useState(null);
    const [lottery_code, setLottery_code] = useState(null);
    const [billing_type, setBilling_type] = useState(null);
    const [email, setEmail] = useState(null);
    const [pec, setPec] = useState(null);
    const [country_code, setCountry_code] = useState(null);
    //const [vat_number, setVat_number] = useState(null);
    //const [fiscal_code, setFiscal_code] = useState(null);
    const [business_name, setBusiness_name] = useState(null);
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [title, setTitle] = useState(null);
    const [eori_code, setEori_code] = useState(null);
    const [office_address, setOffice_address] = useState(null);
    const [office_house_number, setOffice_house_number] = useState(null);
    const [office_zip_code, setOffice_zip_code] = useState(null);
    const [office_collective, setOffice_collective] = useState(null);
    const [office_province, setOffice_province] = useState(null);
    const [office_country_code, setOffice_country_code] = useState(null);
    const [vatNumber, setVatNumber] = useState(null);
    const [fiscalCode, setFiscalCode] = useState(null);
    const [reverseCharge, setRevereseCharge] = useState(false);
    const [message, setMessage] = useState(null);
    const [displayBackdrop, setDisplayBackDrop] = useState(false);

    // Risultato validatori
    const [vatCode, setVatCode] = useState(null);
    const [vatId, setVatId] = useState(null);
    const [agentCode, setAgentCode] = useState(null);
    const [agentId, setAgentId] = useState(null);
    const [causalCode, setCausalCode] = useState(null);
    const [causalId, setCausalId] = useState(null);
    const [listPriceCode, setListPriceCode] = useState(null);
    const [listPriceId, setListPriceId] = useState(null);
    const [vectorCode, setVectorCode] = useState(null);
    const [vectorId, setVectorId] = useState(null);
    const [paymentCode, setPaymentCode] = useState(null);
    const [paymentId, setPaymentId] = useState(null);

    // Validatori
    const [validatorVat, setValidatorVat] = useState(false);
    const [validatorAgent, setValidatorAgent] = useState(false);
    const [validatorCausal, setValidatorCausal] = useState(false);
    const [validatorListPrice, setValidatorListPrice] = useState(false);
    const [validatorVector, setValidatorVector] = useState(false);
    const [validatorCustomerType, setValidatorCustomerType] = useState(false);
    const [validatorCountries, setValidatorCountries] = useState(false);
    const [validatorOfficeCountry, setValidatorOfficeCountry] = useState(false);
    const [validatorOfficeProvince, setValidatorOfficeProvince] = useState(false);
    const [validatorOfficeCollective, setValidatorOfficeCollective] = useState(false);
    const [validatorPayment, setValidatorPayment] = useState(false);

    const navigate = useNavigate();

    const eKeyDown = (e) => {
        const hash = window.location.hash;
        if(
            hash.includes('#vat')       || hash.includes('#agent')              || 
            hash.includes('#causal')    || hash.includes('#list_price')         || 
            hash.includes('#vector')    || hash.includes('#customer_type')      || 
            hash.includes('#countries') || hash.includes('#office_countries')   ||
            hash.includes('#comuni')    || hash.includes('#payment')
        ){
            return;
        }
        SaveAna(e);
    }

    function eHashChange(){
        setValidatorVat(window.location.hash.includes('#vat') && !validatorVat);
        setValidatorAgent(window.location.hash.includes('#agent') && !validatorAgent);
        setValidatorCausal(window.location.hash.includes('#causal') && !validatorCausal);
        setValidatorListPrice(window.location.hash.includes('#list_price') && !validatorListPrice);
        setValidatorVector(window.location.hash.includes('#vector') && !validatorVector);
        setValidatorCustomerType(window.location.hash.includes('#customer_type') && !validatorCustomerType);
        setValidatorCountries(window.location.hash.includes('#countries') && !validatorCountries);
        setValidatorOfficeCountry(window.location.hash.includes('#office_countries') && !validatorOfficeCountry);
        setValidatorOfficeCollective(window.location.hash.includes('#comuni') && !validatorOfficeCollective);
        setValidatorPayment(window.location.hash.includes('#payment') && !validatorPayment);
    }

    useEffect(() => {
        
        window.addEventListener('keydown', eKeyDown);

        window.addEventListener('hashchange', eHashChange);

        if(structure){
		    const obj = structure[structure.length - 1];
            setId(obj.id);
            setCustomerSupplierCode(obj.code);
            setCustomerSupplierDesc(obj.description);
            setVatNumber(obj.vat_number);
            setFiscalCode(obj.fiscal_code);
            setSdi(obj.sdi);
            setBilling_type(obj.billing_type);
            setEmail(obj.email);
            setCountry_code(obj.country_code);
            setPec(obj.pec);
            
            if(obj.vat){
                setVatId(obj.vat.id);
                setVatCode(obj.vat.code);
            }

            if(obj.agent){
                setAgentId(obj.agent.id);
                setAgentCode(obj.agent.code);
            }

            if(obj.causal){
                setCausalId(obj.causal.id);
                setCausalCode(obj.causal.code);
            }

            if(obj.list_price){
                setListPriceId(obj.list_price.id);
                setListPriceCode(obj.list_price.code);
            }

            if(obj.vector){
                setVectorId(obj.vector.id);
                setVectorCode(obj.vector.code);
            }

            if(obj.payment){
                setPaymentCode(obj.payment.code);
                setPaymentId(obj.payment.id);
            }

            setBusiness_name(obj.business_name);
            setName(obj.name);
            setSurname(obj.surname);
            setTitle(obj.title);
            setEori_code(obj.eori_code);
            setOffice_address(obj.office_address);
            setOffice_country_code(obj.office_country_code);
            setOffice_house_number(obj.office_house_number);
            setOffice_zip_code(obj.office_zip_code);
            setOffice_province(obj.office_province);
            setOffice_collective(obj.office_collective);
            setLottery_code(obj.lottery_code);
            setRevereseCharge(obj.reverse);

        }else{

            try{
                
                const setting = JSON.parse(localStorage.getItem('setting'))[0];
                
                if(ent_type == 'customer'){
                    if(setting.list_price_customer){
                        setListPriceCode(setting.list_price_customer.code);
                        setListPriceId(setting.list_price_customer.id);
                    }

                    if(setting.causal_customer){
                        setCausalCode(setting.causal_customer.code);
                        setCausalId(setting.causal_customer.id);
                    }
                }

                if(ent_type == 'supplier'){
                    if(setting.list_price_supplier){
                        setListPriceCode(setting.list_price_supplier.code);
                        setListPriceId(setting.list_price_supplier.id);
                    }

                    if(setting.causal_supplier){
                        setCausalCode(setting.causal_supplier.code);
                        setCausalId(setting.causal_supplier.id);
                    }
                }

            }catch(_){

            }

        }

        return (() => {
            try{
                window.removeEventListener('keydown', eKeyDown);
            }catch(_){}
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    return (
        <div className="parent-new-customer">

            <MbBackdrop display={displayBackdrop}/>
            <MbMessage open={message} message={message} close={() => {
                setMessage(null);
            }} />

            <Typography variant="h6"><ArrowBackIcon sx={{cursor: "pointer"}} onClick={() => RemoveHash()}/>&nbsp;&nbsp;{structure ? "Aggiorna" : "Nuovo"} {ent_type == 'customer' ? "Cliente" : "Fornitore"}</Typography>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice - 5 caratteri"} style={{width: "100%"}} value={customer_supplierCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCustomerSupplierCode(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Descrizione - 200 caratteri"} style={{width: "100%"}} value={customer_supplierDesc} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setCustomerSupplierDesc(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid item xs={12}>
                <TextField size="small" variant="outlined" label={"Ragione sociale - 80 caratteri"} style={{width: "100%"}} value={business_name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                    setBusiness_name(e.target.value);
                }}/>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Partita IVA - 28 caratteri"} style={{width: "100%"}} value={vatNumber} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setVatNumber(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice fiscale (opzionale) - da 11 a 16 caratteri"} style={{width: "100%"}} value={fiscalCode} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setFiscalCode(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Codice lotteria (opzionale) - 8 caratteri"} style={{width: "100%"}} value={lottery_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setLottery_code(e.target.value);
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={`Codice SDI ${ent_type == 'customer' ? '' : '(opzionale) '}- 6 o 7 caratteri`} style={{width: "100%"}} value={sdi} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setSdi(e.target.value);
                    }} InputProps={{
                        endAdornment: <InfoOutlinedIcon sx={{cursor: "pointer"}} onClick={() => {
                            setMessage(
                                `
                                    Per fatture verso pubbliche amministrazioni contiene il codice di 6 caratteri dell'ufficio destinatario della fattura.
                                    Per fatture verso privati contiene il codice di 7 caratteri assegnato dal Sdi ai soggetti che hanno accreditato un canale; qualora il destinatario non abbia accreditato un canale 
                                    presso SdI, l'elemento deve essere valorizzato con tutti zeri ('0000000'). Per le fatture emesse con riferimento a operazioni "transfrontaliere"
                                    l'elemento deve essere valorizzato con tutte "X" ('XXXXXXX')
                                `
                            );
                        }}/>
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={`Tipologia ${ent_type == 'customer' ? '' : '(opzionale)'}`} style={{width: "100%"}} value={billing_type} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#customer_type";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Email"} style={{width: "100%"}} value={email} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setEmail(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Nazione"} style={{width: "100%"}} value={country_code} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#countries";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"PEC (opzionale)"} style={{width: "100%"}} value={pec} InputLabelProps={{ shrink: true }} onChange={(e) => {
                        setPec(e.target.value);
                    }}/>
                </Grid>
            </Grid>

            {/*<div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Aliquota (opzionale)"} style={{width: "100%", cursor: "pointer"}} value={vatCode} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: vatId ? <CloseIcon/> : <SearchIcon/>
                    }} onClick={() => {
                        
                        if(vatId){
                            setVatId(null);
                            setVatCode('');
                            return;
                        }

                        window.location.hash += "#vat";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Agente (opzionale)"} style={{width: "100%", cursor: "pointer"}} value={agentCode} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: agentId ? <CloseIcon/> : <SearchIcon/>
                    }} onClick={() => {
                        
                        if(agentId){
                            setAgentId(null);
                            setAgentCode('');
                            return;
                        }

                        window.location.hash += "#agent";
                    }}/>
                </Grid>
            </Grid>*/}

            <div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Causale (opzionale)"} style={{width: "100%", cursor: "pointer"}} value={causalCode} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: causalId ? <CloseIcon/> : <SearchIcon/>
                    }} onClick={() => {
                        
                        if(causalId){
                            setCausalId(null);
                            setCausalCode('');
                            return;
                        }

                        window.location.hash += "#causal";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Listino"} style={{width: "100%"}} value={listPriceCode} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: <SearchIcon/>
                    }} onClick={() => {
                        window.location.hash += "#list_price";
                    }}/>
                </Grid>
            </Grid>

            {/*<div style={{height: "20px"}}/>

            <Grid container spacing={2}>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Corriere (opzionale)"} style={{width: "100%"}} value={vectorCode} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: vectorId ? <CloseIcon/> : <SearchIcon/>
                    }} onClick={() => {
                        
                        if(vectorId){
                            setVectorId(null);
                            setVectorCode('');
                            return;
                        }

                        window.location.hash += "#vector";
                    }}/>
                </Grid>
                <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                    <TextField size="small" variant="outlined" label={"Pagamento (opzionale)"} style={{width: "100%"}} value={paymentCode} InputLabelProps={{ shrink: true }} disabled InputProps={{
                        endAdornment: paymentId ? <CloseIcon/> : <SearchIcon/>
                    }} onClick={() => {

                        if(paymentId){
                            setPaymentId(null);
                            setPaymentCode('');
                            return;
                        }

                        window.location.hash += "#payment";
                    }}/>
                </Grid>
            </Grid>*/}

            {/*<div style={{height: "20px"}}/>*/}

            {/*<FormGroup>
                <FormControlLabel control={<Checkbox checked={reverseCharge} onChange={(e, checked) => setRevereseCharge(checked)}/>} label="Abilita reverse charge"></FormControlLabel>
            </FormGroup>*/}

            <div style={{height: "20px"}}/>

            {
                // Anagrafica
            }

            {/*<Box>
                <Accordion style={{boxShadow: "none", border: "1px solid #DEDEDE"}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Anagrafica</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{display: "block"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField size="small" variant="outlined" label={"Ragione sociale - 80 caratteri"} style={{width: "100%"}} value={business_name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setBusiness_name(e.target.value);
                                }}/>
                            </Grid>
                        </Grid>

                        <div style={{height: "20px"}}/>

                        <Grid container spacing={2}>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Nome (opzionale)"} style={{width: "100%"}} value={name} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setName(e.target.value);
                                }}/>
                            </Grid>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Cognome (opzionale)"} style={{width: "100%"}} value={surname} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setSurname(e.target.value);
                                }}/>
                            </Grid>
                        </Grid>

                        <div style={{height: "20px"}}/>

                        <Grid container spacing={2}>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Titolo (opzionale)"} style={{width: "100%"}} value={title} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setTitle(e.target.value);
                                }}/>
                            </Grid>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Codice EORI (opzionale)"} style={{width: "100%"}} value={eori_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setEori_code(e.target.value);
                                }}/>
                            </Grid>
                        </Grid>


                        <div style={{height: "20px"}}/>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <div style={{height: "20px"}}/>*/}

            {
                // Sede
            }

            <Box>
                    <AccordionSummary /* expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header" */>
                        <Typography>Sede</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{display: "block"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Indirizzo"} style={{width: "100%"}} value={office_address} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setOffice_address(e.target.value);
                                }}/>
                            </Grid>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Nazione"} style={{width: "100%"}} value={office_country_code} disabled InputLabelProps={{ shrink: true }} InputProps={{
                                    endAdornment: <SearchIcon/>
                                }} onClick={() => {
                                    window.location.hash += "#office_countries";
                                }}/>
                            </Grid>
                        </Grid>

                        <div style={{height: "20px"}}/>

                        <Grid container spacing={2}>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Numero civico (opzionale) - 8 caratteri"} style={{width: "100%"}} value={office_house_number} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setOffice_house_number(e.target.value);
                                }}/>
                            </Grid>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"CAP"} style={{width: "100%"}} value={office_zip_code} InputLabelProps={{ shrink: true }} onChange={(e) => {
                                    setOffice_zip_code(e.target.value);
                                }}/>
                            </Grid>
                        </Grid>

                        <div style={{height: "20px"}}/>

                        <Grid container spacing={2}>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Provincia (opzionale MAX 2 caratteri)"}  style={{width: "100%"}} value={office_province} InputLabelProps={{ shrink: true , }} inputProps={{maxLength: 2}} onChange={(e) => {
                                    setOffice_province(e.target.value);
                                }}/>
                            </Grid>
                            <Grid item xs={window.innerWidth >= 1000 ? 6 : 12}>
                                <TextField size="small" variant="outlined" label={"Comune"} style={{width: "100%"}} value={office_collective} InputLabelProps={{ shrink: true }} InputProps={{
                                    endAdornment: <SearchIcon sx={{cursor: "pointer"}} onClick={() => {
                                        window.location.hash += "#comuni";
                                    }}/>
                                }} onChange={(e) => {
                                    setOffice_collective(e.target.value);
                                }}/>
                            </Grid>
                        </Grid>

                        <div style={{height: "20px"}}/>

                    </AccordionDetails>
            </Box>

            <div style={{height: "100px"}}/>

            <div style={{width: "100%", textAlign: "right"}}>
                <Button variant="contained" className="save-button" onClick={async () => {

                    if(!customer_supplierCode || !customer_supplierDesc){
                        setMessage('Compilare codice e descrizione');
                        return;                        
                    }

                    /*if(customer_supplierCode.length > 5 || customer_supplierDesc.length > 200){
                        setMessage('Il codice ha lunghezza massima 5 e la descrizione 200');
                        return;                        
                    }

                    if(!vatNumber || (vatNumber && vatNumber.length > 28)){
                        setMessage('La partita IVA deve essere compilata con un massimo di 28 caratteri');
                        return;
                    }

                    if(fiscalCode && (fiscalCode.length < 11 || fiscalCode.length > 16)){
                        setMessage('La codice fiscale ha un minimo di 11 caratteri e un massimo di 16!');
                        return;
                    }

                    if(lottery_code && lottery_code.length != 8){
                        setMessage('Il codice lotteria ha lunghezza 8 caratteri');
                        return;                        
                    }

                    if(ent_type == 'customer'){
                        if(!billing_type){
                            setMessage('Compilare la tipologia di cliente!');
                            return;
                        }
                    }

                    if(ent_type == 'customer'){
                        if(!sdi){
                            setMessage('Compilare il codice SDI!');
                            return;  
                        }
                    }

                    if(ent_type == 'customer'){
                        if( (billing_type == 'FPR12' && sdi.length != 7) || (billing_type == "FPA12" && sdi.length != 6) ){
                            setMessage(
                                `
                                    Per fatture verso pubbliche amministrazioni contiene il codice di 6 caratteri dell'ufficio destinatario della fattura.
                                    Per fatture verso privati contiene il codice di 7 caratteri assegnato dal Sdi ai soggetti che hanno accreditato un canale; qualora il destinatario non abbia accreditato un canale 
                                    presso SdI, l'elemento deve essere valorizzato con tutti zeri ('0000000'). Per le fatture emesse con riferimento a operazioni "transfrontaliere"
                                    l'elemento deve essere valorizzato con tutte "X" ('XXXXXXX')
                                `
                            );
                            return;
                        }
                    }

                    if(!email){
                        setMessage('Compilare email!');
                        return;  
                    }

                    if(!country_code){
                        setMessage('Compilare nazione!');
                        return;  
                    }

                    if(!business_name || (business_name && business_name.length > 80)){
                        setMessage('La ragione sociale deve essere compilata e ha lunghezza massima 80 caratteri!');
                        return;  
                    }

                    if(!office_address){
                        setMessage('Compilare indirizzo sede');
                        return;
                    }

                    if(!office_country_code){
                        setMessage('Compilare nazione sede');
                        return;
                    }

                    if(!office_collective){
                        setMessage('Compilare comune sede');
                        return;
                    }

                    if(!office_province || (office_province && office_province.length !== 2)){
                        setMessage('La provincia deve essere compilata con due caratteri!');
                        return;
                    }

                    if(!office_zip_code){
                        setMessage('Compilare CAP');
                        return;
                    }
                    
                    if(office_house_number && office_house_number.length > 8){
                        setMessage('Il numero civico ha lunghezza massima 8 caratteri');
                        return;
                    }*/

                    setDisplayBackDrop(true);
                    setTimeout(async () => {

                        const result = await MbApi_CustomerSupplierCreate(JSON.stringify({
                            id                  : id,
                            code                : customer_supplierCode,
                            description         : customer_supplierDesc,
                            entity_type         : ent_type,
                            sdi                 : sdi ?? "0000000",
                            lottery_code        : lottery_code ?? "0000000",
                            billing_type        : billing_type ?? "FPR12",
                            email               : email ?? "not-valid@test.it",
                            pec                 : pec ?? "not-valid@test.it",
                            country_code        : country_code ?? "IT",
                            vat_number          : vatNumber ?? "00000000000",
                            fiscal_code         : fiscalCode ?? "00000000000000",
                            business_name       : business_name ?? "NO_BUSINESS_NAME",
                            name                : name ?? "NO NAME",
                            surname             : surname ?? "NO NAME",
                            title               : title ?? "",
                            eori_code           : eori_code ?? "",
                            office_address      : office_address ?? "",
                            office_house_number : office_house_number ?? "",
                            office_zip_code     : office_zip_code ?? "",
                            office_collective   : office_collective ?? "",
                            office_province     : office_province ?? "",
                            office_country_code : office_country_code ?? "",
                            vat                 : vatId ? {id: vatId, code: vatCode} : null,
                            causal              : causalId ? {id: causalId, code: causalCode} : null,
                            agent               : agentId ? {id: agentId, code: agentCode} : null,
                            vector              : vectorId ? {id: vectorId, code: vectorCode} : null,
                            list_price          : listPriceId ? {id: listPriceId, code: listPriceCode} : null,
                            payment             : paymentId ? {id: paymentId, code: paymentCode} : null,
                            reverse             : reverseCharge ? 1 : 0
                        }));

                        setDisplayBackDrop(false);

                        if(result){
                            setId('');
                            setCustomerSupplierCode('');
                            setCustomerSupplierDesc('');
                            setEntity_type('');
                            setSdi('');
                            setLottery_code('');
                            setBilling_type('');
                            setEmail('');
                            setPec('');
                            setCountry_code('');
                            setVatNumber('');
                            setFiscalCode('');
                            setBusiness_name('');
                            setName('');
                            setSurname('');
                            setTitle('');
                            setEori_code('');
                            setOffice_address('');
                            setOffice_house_number('');
                            setOffice_zip_code('');
                            setOffice_collective('');
                            setOffice_province('');
                            setOffice_country_code('');
                            setVatNumber('');
                            setFiscalCode('');
                            setVatCode('');
                            setVatId(null);
                            setAgentCode('');
                            setAgentId(null);
                            setCausalCode('');
                            setCausalId(null);
                            setListPriceCode('');
                            setListPriceId(null);
                            setVectorCode('');
                            setVectorId(null);
                            setPaymentCode('');
                            setPaymentId(null);
                            setRevereseCharge(false);
                            comeback();
                        }

                        setMessage(result ? `${ent_type == 'customer' ? 'Cliente' : 'Fornitore'} salvato con successo!` : `Errore durante il salvataggio del ${ent_type == 'customer' ? 'cliente' : 'fornitore'}`);
                        
                    }, 1000);

                }} id="btn_save">SALVA</Button>
            </div>

            {

                // Validatore aliquota IVA

                validatorVat ?
                    <MbValidatorVat
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#vat").join("");
                            setVatCode(value[1]);
                            setVatId(value[0]);
                        }}
                    />
                :
                    <></>
            }

            {

                // Validatore agente

                validatorAgent ?
                    <MbValidatorAgent
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#agent").join("");
                            setAgentCode(value[1]);
                            setAgentId(value[0]);
                        }}
                    />
                :
                    <></>
            }

            {

                // Validatore causale

                validatorCausal ?
                    <MbValidatorCausal
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#causal").join("");
                            setCausalCode(value[1]);
                            setCausalId(value[0]);
                        }}
                    />
                :
                    <></>
            }

            {

                // Validatore listino

                validatorListPrice ?
                    <MbValidatorListPrice
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#list_price").join("");
                            setListPriceCode(value[1]);
                            setListPriceId(value[0]);
                        }}
                    />
                :
                    <></>
            }

            {

                // Validatore vettore

                validatorVector ?
                    <MbValidatorVector
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#vector").join("");
                            setVectorCode(value[1]);
                            setVectorId(value[0]);
                        }}
                    />
                :
                    <></>
            }


            {

                // Validatore tipologia cliente

                validatorCustomerType ?
                    <MbValidatorCustomerType
                        close={() => {
                            window.location.hash = window.location.hash.split("#customer_type").join("");
                        }}
                        choosed={(value) => {
                            window.location.hash = window.location.hash.split("#customer_type").join("");
                            setBilling_type(value[0]);
                        }}
                    />
                :
                    <></>
            }

            {
                // Validatore nazioni
                validatorCountries || validatorOfficeCountry ? 
                    <MbValidatorNations close={() => {
                        window.location.hash = window.location.hash.split("#office_countries").join("");
                        window.location.hash = window.location.hash.split("#countries").join("");
                    }} choosed={(value) => {
                        if(validatorOfficeCountry){
                            setOffice_country_code(value[0]);
                        }else{
                            setCountry_code(value[0]);
                        }
                        window.location.hash = window.location.hash.split("#countries").join("");
                        window.location.hash = window.location.hash.split("#office_countries").join("");
                    }}/>
                :
                    <></>
            }

            {

                // Validatore comuni

                validatorOfficeCollective ?
                    <MbValidatorComuni
                        choosed={(value) => {
                            window.location.hash = window.location.hash.split("#comuni").join("");
                            setOffice_collective(value[2]);
                            setOffice_province(value[3]);
                            setOffice_zip_code(value[1]);
                        }}
                    />
                :
                    <></>

            }

            {

                // Validatore pagamenti

                validatorPayment ?
                    <MbValidatorPayment
                        callbackFromValidator={(value) => {
                            window.location.hash = window.location.hash.split("#payment").join("");
                            setPaymentId(value[0]);
                            setPaymentCode(value[1]);
                        }}
                    />
                :
                    <></>

            }

        </div>
    );

};

export {
    MbAnagraficaNewCustomerSupplier
}