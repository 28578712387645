import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { MbApi_ProductDelete, MbApi_ProductList } from "../../mb_api/mb_api_product";
import { MbBackdrop } from "../../mb_components/mb_backdrop";
import { MbMessage } from "../../mb_components/mb_message";
import { Endpoint, Permission, PrimaryColor, RecordsForPage, RemoveHash, SecondayColor, usePermission, useProductTypePath } from "../../mb_constants";
import { MbTable } from "../../mb_table/mb_table";
import { MbProposeColumns, MbSaveColumnsForTable } from "../../mb_table/mb_table_api";
import { MbAnagraficaNewProduct } from "../mb_anagrafica_new_product";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import MultipleStopOutlinedIcon from '@mui/icons-material/MultipleStopOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { redux_set_product_composition } from "../../mb_redux/mb_redux_composition_product";
import { useNavigate } from "react-router-dom";
import { Check,Close } from "@material-ui/icons";

const MbAnagraficaProduct = ({isValidator, callbackFromValidator, displayOnlyForComposition, displayOnlySimpleProduct, displayProductNotForComposition, searchDataValidator}) => {

    const [page, setPage]                       = useState(0);
    const [data, setData]                       = useState([]);
    const [records, setRecords]                 = useState(-1);
    const [displayNew, setDisplayNew]           = useState(false);
    const [search, setSearch]                   = useState(null);
    const [structUpdate, setStructUpdate]       = useState(null);
    const [displayBackdrop, setDisplayBackdrop] = useState(false);
    const [displayMessage, setDisplayMessage]   = useState(false);
    const entType                               = useProductTypePath();
    const permission                            = usePermission();
    const navigate                              = useNavigate();

    // Redux
    const dispatch = useDispatch();
    const reduxProductsForComposition = useSelector((state) => state.product_composition.value);

    function fetchData(){
        //MbApi_ProductList(page, search, displayOnlyForComposition, displayOnlySimpleProduct, displayProductNotForComposition, window.location.pathname.replace("/", ""))
        MbApi_ProductList(page, search, null, null, null, window.location.pathname.replace("/", ""), isValidator ? null : "1", null).then((response) => {
            if(response.Result == "OK"){
                setRecords(response.Json.count);
                const items = [];
                if(response.Json.count > 0){
                    response.Json.products.map((item, index) => {
                        
                        const vat_value = item.vat ? (item.vat.value ?? 0.00) : 0;
                        const price = item.prices && item.prices.length > 0 ? parseFloat( (item.prices[0].taxable ?? 0.00).toString()) : 0;
                        const tax = (price * vat_value) / 100;
                        const full_price = price + tax;

                        items.push([
                            item.id,
                            <div style={{display: "flex", justifyContent: window.innerWidth >= 1000 ? "center" : "start"}}>
                                {
                                    item.image ? 
                                        item.image.startsWith('http') ? 
                                            <Avatar alt="Immagine prodotto" src={item.image}></Avatar>
                                        :
                                            <Avatar alt="Immagine prodotto" src={item.image ? (Endpoint + item.image) : ""}></Avatar>
                                    :
                                        <Avatar/>
                                }
                            </div>, 
                            item.code,
                            item.code_balance, //codice bilancia
                            item.description,
                            (item.prices ?? []).length > 0 ? full_price.toFixed(2).replace(".", ",") : "0,00",
                            item.products_categories_hierarchy && item.products_categories_hierarchy.length > 0 ? item.products_categories_hierarchy[0].category.description : "",
                            item.disabled != true  ? <Close></Close> : <Check/> , //abilitato sulla riga
                            item.position ?? "0",
                            item.vat.value.toFixed(2).replace(".", ",") + "%",
                            item.um.description,
                            <AttachMoneyOutlinedIcon sx={{color: SecondayColor}}/>,
                            <>
                                <MultipleStopOutlinedIcon sx={{color: SecondayColor}}/>
                            </>,
                            <>
                                <BrandingWatermarkOutlinedIcon sx={{color: SecondayColor}}/>
                            </>,
                            <>
                                <GroupWorkOutlinedIcon sx={{color: SecondayColor}}/>
                            </>,
                            item // Copia originale del prodotto
                        ]);
                    })
                }
                setData(items);
            }
        });
    }

    useEffect(() => {
        if(page == -1){
            setPage(0);
            return;
        }
        fetchData();
    }, [page]);
    
    function eHashChange(){
        const _displayNew = window.location.hash.includes('new') && !displayNew;
        setDisplayNew(_displayNew);
        if(!_displayNew){
            setStructUpdate(null);
        }
    }

    useEffect(() => {
        if(searchDataValidator){
            setTimeout(() => {
                setSearch(searchDataValidator);
                setPage(-1);
            }, 100);
        }
    }, [searchDataValidator]);

    useEffect(() => {
        
        MbSaveColumnsForTable("prodotti", [
            "ID", 
            "Immagine", 
            "Codice",
            "Bilancia", 
            "Descrizione", 
            "Prezzo",
            "Categoria",
            "Disabilitato"
            //"Posizione", 
            //"Aliquota", 
            //"Unità di misura"
        ]);

        window.addEventListener('hashchange', eHashChange);

        return (() => {
            try{
                window.removeEventListener('hashchange', eHashChange);
            }catch(_){}
        });

    }, []);

    useEffect(() => {
        if(!window.location.pathname.includes("new_doc") && !window.location.hash.includes('#validator')){
            RemoveHash();
            setDisplayNew(false);
            setSearch(null);
            setStructUpdate(null);
            setPage(-1);
        }
    }, [window.location.pathname]);

    return (
        <>  
            <MbMessage open={displayMessage} close={() => setDisplayMessage(null)} message={displayMessage} />
            <MbBackdrop display={displayBackdrop}/>
            {
                displayNew ? 
                    <MbAnagraficaNewProduct 
                        productType={
                            window.location.pathname.includes("menu") ? 
                                "menu"
                            :
                                window.location.pathname.includes("department") ? 
                                    "department"
                                :
                                    ""
                        }
                        structure={structUpdate}
                        comeback={() => {
                            setStructUpdate(null);
                            setPage(-1);
                            //navigate(-1);
                        }}
                    />
                :
                    <MbTable
                        isValidator={isValidator}
                        table={"prodotti"}
                        columns={
                            MbProposeColumns( 
                                'prodotti',
                                [
					                {"name": "ID"}, 
                                    {"name": "Immagine"}, 
                                    {"name": "Codice"}, 
                                    {"name": "Bilancia"}, 
                                    {"name": "Descrizione"}, 
                                    {"name": "Prezzo"},
                                    {"name": "Categoria"},
                                    {"name": "Abilitato"}
                                    //{"name": "Posizione"}, 
                                    //{"name": "Aliquota"}, 
                                    //{"name": "Unità di misura"}
                                ]
                            )
                        }
                        rows={[...data]}
                        displayDelete={entType == "product" ? (permission.p_product_delete == 1) : ( entType == "menu" ? (permission.p_menu_delete == 1) : (permission.p_department_delete == 1) )}
                        displayNew={entType == "product" ? (permission.p_product_create == 1) : ( entType == "menu" ? (permission.p_menu_create == 1) : (permission.p_department_create == 1) )}
                        displayRead
                        displayUpdate={entType == "product" ? (permission.p_product_create == 1) : ( entType == "menu" ? (permission.p_menu_create == 1) : (permission.p_department_create == 1) )}
                        recordForPage={RecordsForPage}
                        totalRecords={records}
                        page={page}
                        pageChange={(page) => {
                            setPage(page - 1);
                        }}
                        changedRecordForPage={() => {
                            if(page == 0){
                                setPage(-1);
                            }else{
                                setPage(0);
                            }
                        }}
                        changedColumnsTable={() => {
                            setPage(-1);
                        }}
                        searchEventOutside={(value) => {
                            setPage(-1);
                            setSearch(value);
                        }}
                        clickUpdate={async (data) => {
                            
                            setDisplayBackdrop(true);

                            const prd = await MbApi_ProductList(0, null, null, null, null, window.location.pathname.replace("/", ""), null, data[0]);
                            await new Promise((resolve) => setTimeout(() => resolve(), 500));
                            setDisplayBackdrop(false);
                            if(prd && prd.Json && prd.Json.products.length > 0){
                                let obj = prd.Json.products[0];
                                if(obj.image){
                                    if(!obj.image.includes('http'))
                                        obj.image = Endpoint + obj.image;
                                }
    
                                setStructUpdate(obj);
                                window.location.hash = '#new';
                            }

                            /*let obj = data[data.length - 1];
                            if(obj.image){
                                if(!obj.image.includes('http'))
                                    obj.image = Endpoint + obj.image;
                            }

                            setStructUpdate(obj);
                            window.location.hash = '#new';*/
                        }}
                        deleteEvent={(data) => {
                            setDisplayBackdrop(true);
                            setTimeout(async () => {
                                const deleted = await MbApi_ProductDelete(data[0]);
                                setDisplayBackdrop(false);
                                if(deleted){
                                    const obj = data[data.length - 1];
                                    if(obj.product_for_composition == 1){
                                        if(reduxProductsForComposition && reduxProductsForComposition.length > 0){
                                            const prds = [...reduxProductsForComposition];
                                            const index = prds.findIndex(item => item.id == obj.id);
                                            if(index > -1){
                                                prds.splice(index, 1);
                                                dispatch(redux_set_product_composition(prds));
                                            }
                                        }
                                    }
                                    setPage(-1);
                                }
                                setDisplayMessage(deleted ? "Record cancellato con successo!" : "Errore durante la cancellazione del record!");
                            }, 1000);   
                        }}
                        clickRow={(item) => {
                            if(isValidator){
                                callbackFromValidator(item);
                            }
                        }}
                        displayRecordForPage
                        displaySetting
                        clickNew={() => window.location.hash = "#new" }
                        key="TABLE_PRODOTTI"
                    />
            }
        </>
    );

};

export {
    MbAnagraficaProduct
}